/**
 * 在 gatsby 專案中引入 package css file 會出現錯誤
 * Error Message: `No PostCSS Config found in: {pathe to file}`
 * 參考解法：{@link https://stackoverflow.com/questions/48779212/react-slick-import-css-from-slick-carousel-fails}
 * 直接複製 package 的 css 檔案
 */
import './_slick.css'
import './_slick-theme.css'

import React, { ReactElement } from 'react'
import Slider, { Settings } from 'react-slick'
import styled, { FlattenSimpleInterpolation } from 'styled-components'

import downDefault from '../../assets/images/down_default.svg'
import { space } from '../../constants/length'

const Wrap = styled.div<{ showDots: boolean; customStyle?: FlattenSimpleInterpolation }>`
  margin: 0 auto;
  ${props => (props.showDots ? `padding-bottom: 25px` : '')};
  ${props => props.customStyle}
`

const StyledDefaultArrow = styled.img<{ direction: 'next' | 'prev' }>`
  width: ${space.l}px;
  height: ${space.l}px;
  transform: rotate(${props => (props.direction === 'next' ? '-90deg' : '90deg')});
`

interface DefaultArrowProps {
  src: string
  alt: string
  direction: 'next' | 'prev'
}
const DefaultArrow = (props: DefaultArrowProps) => {
  const { src, alt, direction, ...restProps } = props
  return (
    <div {...restProps}>
      <StyledDefaultArrow src={src} alt={alt} direction={direction} />
    </div>
  )
}

const defaultSliderSetting = {
  dots: true,
  infinite: true,
  prevArrow: <DefaultArrow src={downDefault} alt='上一個' direction='prev' />,
  nextArrow: <DefaultArrow src={downDefault} alt='下一個' direction='next' />
}

interface CarouselProps extends Settings {
  customStyle?: FlattenSimpleInterpolation
  children: ReactElement | ReactElement[]
  getRef?: (slick: Slider | null) => void
}

const Carousel = (props: CarouselProps) => {
  const { children, getRef, customStyle, ...restProps } = props
  const showDots = children instanceof Array && children.length > 1 && restProps.dots !== false

  return (
    <Wrap customStyle={customStyle} showDots={showDots}>
      <Slider ref={slick => getRef && getRef(slick)} {...defaultSliderSetting} {...restProps}>
        {children}
      </Slider>
    </Wrap>
  )
}

export default Carousel
