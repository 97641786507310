import { Select } from 'antd'
import { map } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { space } from '../../constants/length'
import { Primary } from '../../constants/newColor'
import clinicInfoList from '../Clinic/Info'

// 覆寫 antd select 的 style
const CustomizedSelect = styled(Select)`
  line-height: 32px;
  margin-bottom: ${space.l}px;
  font-size: 16px;

  .ant-select-focused {
    border: none !important;
    box-shadow: none !important;

    .ant-select-arrow {
      color: ${Primary} !important;
    }
  }

  .ant-select-open {
    border: none !important;
    box-shadow: none !important;

    .ant-select-arrow {
      color: ${Primary} !important;
    }
  }

  .ant-select:hover {
    .ant-select-arrow {
      color: ${Primary} !important;
    }
  }

  .ant-select-selector {
    color: ${Primary};
    box-shadow: none !important;
    :hover,
    :active,
    :focus {
      border: 1px solid ${Primary} !important;
    }
  }
`

interface ClinicSelectProps {
  onSelect: (value: string) => void
  value?: string
}

const ClinicFilter = (props: ClinicSelectProps) => {
  const { value, onSelect } = props

  const InfoList = map(clinicInfo => ({ label: clinicInfo.area, options: clinicInfo.clinics.map(clinic => ({ label: clinic.name, value: clinic.value })) }), clinicInfoList)

  const All = [{ label: '地區', options: [{ label: '全部診所', value: 'ALL' }] }]
  const List = All.concat(InfoList)

  const handleChange = value => {
    onSelect(value)
  }

  return <CustomizedSelect onChange={value => handleChange(value)} value={value} defaultValue='ALL' style={{ width: 200 }} options={List} />
}

export default ClinicFilter
