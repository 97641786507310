import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { map } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { LightGrey, Primary, Secondary } from '../../constants/newColor'

interface Option {
  label: string
  value: string
}

const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-wrap: wrap;
  border: none;
`

const CustomizedRadio = styled(Radio.Button)`
  &.ant-radio-button-wrapper {
    border: 1px solid ${Primary};
    border-radius: 30px;
    margin: 0.3rem;
    background-color: ${LightGrey};

    :before {
      display: none;
    }
    :hover {
      color: ${Secondary};
    }
  }
  &.ant-radio-button-wrapper-checked {
    background-color: ${Primary};
    color: ${LightGrey};
    &.ant-radio-button-wrapper {
      :first-child {
        border: 1px solid ${Primary};
      }
    }

    :hover {
      cursor: default;
      color: ${LightGrey};
      border: 1px solid ${Primary};
    }
  }
`
interface Props {
  value?: string
  onSelect: (value: string) => void
  options: Option[]
}

const RadioFilter = (props: Props) => {
  const { value, onSelect, options } = props

  const onChange = (e: RadioChangeEvent) => {
    onSelect(e.target.value)
  }

  return (
    <>
      <RadioGroup value={value} onChange={onChange}>
        {map(
          option => (
            <CustomizedRadio key={option.value} value={option.value}>
              {option.label}
            </CustomizedRadio>
          ),
          options
        )}
      </RadioGroup>
    </>
  )
}

export default RadioFilter
