import { DecodedValueMap, QueryParamConfigMap, UrlUpdateType, useQueryParams } from 'use-query-params'

const useFormQuery = <QPCMap extends QueryParamConfigMap>(queryParams: QPCMap) => {
  const [formQuery, setFormQuery] = useQueryParams<QPCMap>(queryParams)

  const handleFormChange = (changes: Partial<DecodedValueMap<QPCMap>>, updateType: UrlUpdateType = 'pushIn') => {
    setFormQuery(changes, updateType)
  }

  const handleFormReset = () => {
    setFormQuery({}, 'push')
  }

  return {
    formQuery,
    handleFormChange,
    handleFormReset
  }
}

export default useFormQuery
