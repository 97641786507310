import { map, take } from 'ramda'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import DownArrow from '../assets/images/down-arrow.svg'
import { space } from '../constants/length'
import { Primary } from '../constants/newColor'
import { viewport } from '../constants/viewport'
import { GridLayout } from './Grid'

const FontSize = css`
  color: ${Primary};

  @media (max-width: ${viewport.desktop}px) {
    font-size: 0.75rem; //12px
    line-height: 1.5rem; //24px
    margin-top: ${space.xxl}px;
  }
  @media (min-width: ${viewport.desktop + 1}px) {
    font-size: 1rem; //16px
    line-height: 1.5rem; //24px
    margin-top: ${space.xl * 2}px;
  }
`

const LoadMoreBtn = styled.div`
  ${FontSize};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  & :after,
  :before {
    content: '';
    display: block;
    margin: 0 12px;
    width: 20px;
    height: 25px;
    background-image: url(${DownArrow});
    background-repeat: no-repeat;
    background-size: auto;

    animation-name: position-keyframe;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate-reverse;

    @keyframes position-keyframe {
      from {
        background-position: top;
      }
      to {
        background-position: bottom;
      }
    }
  }
`

const EndLine = styled.div`
  ${FontSize};
  display: flex;
  justify-content: center;
`

interface Props {
  defaultItems: number
  perClick: number
  amount: number
  children: any
  itemList: any
  columnGap?: string[]
  rowGap?: string[]
  templateColumns: string[]
  templateRows?: string[]
  templateAreas?: string[]
}
const LoadMore = (props: Props) => {
  const { amount, defaultItems, perClick, itemList, children, columnGap, rowGap, templateColumns, templateRows, templateAreas } = props

  const [displayItems, setDisplayItems] = useState(defaultItems)
  const hasMoreItems = amount > displayItems
  const showMoreItems = () => {
    setDisplayItems(displayItems + perClick)
  }
  const displayList = take(displayItems, itemList)

  return (
    <>
      <GridLayout templateColumns={templateColumns} templateRows={templateRows} columnGap={columnGap} rowGap={rowGap} templateAreas={templateAreas}>
        {map(items => children(items), displayList)}
      </GridLayout>
      {hasMoreItems ? <LoadMoreBtn onClick={showMoreItems}>加載更多文章</LoadMoreBtn> : <EndLine>———— 沒有更多文章囉 ————</EndLine>}
    </>
  )
}

export default LoadMore
